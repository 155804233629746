<template>
    <div class="d-flex flex-column justify-center align-center fill-height">
        <div class="cloud-wrapper">
            <v-card v-if="!userInfo || !userInfo.uid" width="350">
                <v-card-title class="justify-center align-center nuvolos-header">
                    <v-img max-width="200" max-height="22" class="py-5" src="@/assets/Nuvolos-transparent.svg" />
                </v-card-title>
                <h2 class="secondary--text text-center text-h6 mt-5">Nuvolos account details</h2>
                <v-card-text class="black--text">
                    <p>
                        This is the first time you are accessing Nuvolos. Please provide some details to create a Nuvolos account associated with your
                        {{ isGoogleOauth2 ? 'google' : 'university' }} login:
                    </p>
                    <v-form ref="form" v-model="valid" class="mt-3">
                        <v-text-field
                            v-model="firstName"
                            :autofocus="!firstName"
                            :counter="50"
                            label="First Name"
                            :rules="[rules.nonEmpty]"
                            required
                            outlined></v-text-field>
                        <v-text-field v-model="lastName" :counter="50" label="Last Name" :rules="[rules.nonEmpty]" required outlined></v-text-field>
                        <v-text-field v-model="email" label="E-mail" required readonly outlined></v-text-field>
                    </v-form>
                    <v-card-actions>
                        <v-btn color="primary" block :disabled="!valid || email === ''" :loading="creatingAccount" @click="createAccount">
                            <v-icon class="mr-2">mdi-link-variant</v-icon>
                            Create Linked Account
                        </v-btn>
                    </v-card-actions>
                </v-card-text>
            </v-card>
            <img src="@/assets/cloud-1.svg" class="cloud cloud-1" />
            <img src="@/assets/cloud-3.svg" class="cloud cloud-3" />
        </div>
        <v-btn text @click="showIntercomChat" color="primary" small class="mt-4">
            <v-icon class="mr-1" small>support</v-icon>
            Ask for help
        </v-btn>
        <TheInvitationModal message="Creating account" :show="creatingAccount" />
    </div>
</template>
<script>
import { intercomMethods } from '@/mixins/intercom'
import { mapState } from 'vuex'
const TheInvitationModal = () => import('../components/TheInvitationModal')

export default {
    mixins: [intercomMethods],
    data() {
        return {
            creatingAccount: false,
            firstName: this.$auth.user.given_name || '',
            lastName: this.$auth.user.family_name || '',
            email: '',
            valid: false,
            rules: {
                nonEmpty: p => p.length > 0 || 'Please provide a value.'
            }
        }
    },
    created() {
        if (this.userInfo && this.userInfo.uid) {
            this.$router.push({
                name: 'invitation-accept',
                params: { invid: this.$route.params.invid }
            })
        }
    },
    mounted() {
        this.$axios.get(`/invitations/describe/${this.$route.params.invid}`).then(response => {
            this.email = response.data.requestee_email
        })
    },
    computed: {
        ...mapState(['userInfo', 'fetchingUserInfo']),
        isGoogleOauth2() {
            return this.$route.params.type === 'google'
        }
    },
    methods: {
        createAccount() {
            this.creatingAccount = true
            const type = this.$route.params.type === 'google' ? 'google-oauth2' : 'satosa'
            this.$axios
                .post(
                    `/users/register_with_invitation/${type}/${this.$route.params.invid}`,
                    {
                        email: this.email,
                        family_name: this.lastName,
                        given_name: this.firstName
                    },
                    { timeout: 300000 }
                )
                .then(() => {
                    this.creatingAccount = false
                    this.$store.dispatch('getUserInfo')
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Account created successfully!',
                        snackBarTimeout: 10000,
                        snackBarIcon: 'check_circle'
                    })
                    this.$router.push({
                        name: 'invitation-accept',
                        params: { invid: this.$route.params.invid }
                    })
                })
                .catch(error => {
                    if (!error.response || !error.response.data || error.response.data.code !== 'already_registering') {
                        this.$store.dispatch('showSnackBar', {
                            snackBarText: 'Account creation failed, please try again later.',
                            snackBarTimeout: 10000,
                            snackBarIcon: 'error'
                        })
                    }
                })
                .finally(() => {
                    this.creatingAccount = false
                })
        }
    },
    components: { TheInvitationModal }
}
</script>

<style lang="scss" scoped>
@import '@/sass/signup.scss';
</style>
